import React from "react";

function SuccessIcon() {
  return (
 <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="36" height="36" rx="18" fill="#E4F7E2"/>
<rect x="2" y="2" width="40" height="40" rx="20" stroke="#E4F6E2" stroke-opacity="0.5" stroke-width="4"/>
<path d="M32 21.0857V22.0057C31.9988 24.1621 31.3005 26.2604 30.0093 27.9875C28.7182 29.7147 26.9033 30.9782 24.8354 31.5896C22.7674 32.201 20.5573 32.1276 18.5345 31.3803C16.5117 30.633 14.7847 29.2518 13.611 27.4428C12.4373 25.6338 11.8798 23.4938 12.0217 21.342C12.1636 19.1903 12.9972 17.1421 14.3983 15.5028C15.7994 13.8635 17.6928 12.7211 19.7962 12.2459C21.8996 11.7706 24.1003 11.9881 26.07 12.8657M32 14.0057L22 24.0157L19 21.0157" stroke="#13540C" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  );
}

export default SuccessIcon;




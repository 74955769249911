import React, { FC } from "react";
import { Stack, Text } from "@fluentui/react";
import { useTranslation } from "react-i18next";

type CustomToggleProps = {
  active: boolean;
  setActive: any;
  height: string;
  width: string;
  borderRadius: string;
  handleChange: any;
  data?: boolean;
  blueText?: string;
  redText?: string;
  switchDotWidth?: string;
  switchDotHeight?: string;
  customInactiveLeft?: string;
  customActiveLeft?:string;
  disabled?: boolean;
};
const CustomToggle: FC<CustomToggleProps> = (props) => {
  const toggleSwitchStyles = {
    width: props.width,
    height: props.height,
    backgroundColor: props.active ? "#18468F" : "#A82C0C",
    borderRadius: props.borderRadius,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1px",
  };
  const inActiveLeft = props.customInactiveLeft
    ? props.customInactiveLeft
    : "4%";

    const activeLeft = props.customActiveLeft ? props.customActiveLeft :"calc(100% - 33%)"
  const switchHandleStyles = {
    width: props.switchDotWidth
      ? props.switchDotWidth
      : props.data
      ? "30%"
      : "46%",
    height: props.switchDotHeight
      ? props.switchDotHeight
      : props.data
      ? "80%"
      : "83.3%",
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    left: props.active
      ? props.data
        ? activeLeft
        : "calc(100% - 50%)"
      : props.data
      ? inActiveLeft
      : "5%",
    transition: "left 0.3s",
    alignSelf: "center",
  };

  const toggleSwitch = () => {
    if (!props.disabled) {
      props.handleChange();
    }
  };
  const { t } = useTranslation();

  return (
    <Stack
      onClick={toggleSwitch}
      style={{ ...toggleSwitchStyles, position: "relative" }}
    >
      <Stack style={{ ...switchHandleStyles, position: "absolute" }}></Stack>
      {props.data ? (
        <Text
          style={{
            color: "var(--WHITE, #FFF)",
            lineHeight: "20px",
            fontFamily: "Poppins",
            fontSize: "10px",
            position: "absolute",
            left: props.active ? 6 : 25,
          }}
        >
          {/* {props.active ? t("clean") : t("track")} */}
          {props.active ? props.blueText : props.redText}
        </Text>
      ) : null}
    </Stack>
  );
};

export default CustomToggle;

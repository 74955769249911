import { Providers } from "@microsoft/mgt-element";
import { Project, response } from "../models";
import { ApiClient } from "../network/ApiClient";
import { ApiEndpoints } from "../network/ApiEndpoints";

class ProjectService {
  public async get(id: number): Promise<Project> {
    return Promise.resolve({ projectId: id } as Project);
  }

  public async create(payload: Project): Promise<response> {
    const response: response = await ApiClient.post(
      ApiEndpoints.createProject(),
      {
        projectName: payload.projectName,
        projectValue: payload.projectValue,
        counterParties: payload.counterParties,
        description: payload.description,
        projectOwner: payload.projectOwner,
        projectStatus: payload.projectStatus,
      },
      {},
      {}
    );
    return Promise.resolve(response);
  }

  public async update(id: number, payload: Project): Promise<Project> {
    return Promise.resolve({ ...payload, projectId: id } as Project);
  }

  public async delete(id: string): Promise<void> {
    return ApiClient.delete("api-endpoint", { projectId: id });
  }

  public async changeStatusOfProject(payload: any): Promise<any> {
    const response = await ApiClient.post(
      ApiEndpoints.changeStatusOfProject(),
      payload
    );
    return Promise.resolve(response);
  }

  public async getStatuses() {
    const response = await ApiClient.get(ApiEndpoints.getStatusesOfProject());
    return Promise.resolve(response);
  }

  public async projectInfo(id: number) {
    const response = await ApiClient.get(ApiEndpoints.projectInfo(id));
    return Promise.resolve(response);
  }
}

const projectService = new ProjectService();
export { projectService as ProjectService };

export const colorPallete = {
    white:"#ffffff",
    black:"#1F2430",
    black1:"#323232",
    black2:"#000000",
    grey:"#51596C",
    grey1:"#707070",
    grey2:"#DAE0EC",
    grey3:"#DFE6EF",
    darkGrey:"#101828",
    reviewBlue:"#007CFF",
    btRed:"#A82C0C",
    btBlue:"#18468F",
    btGreen:"#13540C",
    btLightBlue:"#E8ECF4",
    btLightGreen:"#E7EFE6",
    btLightRed:"#F6EAE7",
    btShared:"#2AAD1B",
    btRedLined:"#ED4B4B",
    lightGrey2:"#DFDFDF",
    lightestGrey:"#F4F4F4",
    background:"#F3F5F9",
    backgroundRed:"#F9F3F3",
    btBlue2:"#0E4394",
    grey4:"#1F243080"
}
import { FC } from "react";
import { initializeIcons, Stack, Text } from "@fluentui/react";
import { FontSizes } from "@fluentui/react/lib/Styling";
import { colorPallete } from "../../../assets/color";
import "../FunctionalityButtonStack/index.css";
import DraftIcon from "../../../assets/Icons/svg/draftIcon";
import NegotiateIcon from "../../../assets/Icons/svg/negotiateIcon";
import SignIcon from "../../../assets/Icons/svg/signIcon";

interface FunctionalityButtonProps {
  buttonText: string;
  color: string;
  onClickFunction: () => void;
  // height: string;
  // width: string;
  // title:string;
  flex: string;
  icon: string;
}

const styles = {
  headerStyle: {
    fontSize: "1.5rem",
    color: colorPallete.white,
    marginLeft: "0.8vw",
    fontFamily: "Poppins",
    fontWeight:'500'
  },
  iconClass: {
    fontSize: FontSizes.size42,
    color: colorPallete.white,
    margin: "3%",
  },
  headerText: {
    fontSize: "0.9rem",
    color: colorPallete.white,
    marginLeft: "1vh",
    fontFamily: "Poppins",
  },
};

initializeIcons();
export const FunctionalityButton: FC<FunctionalityButtonProps> = (props) => {
  function getIcon() {
    if (props.icon === "draft") {
      return <DraftIcon />;
    } else if (props.icon === "negotiate") {
      return <NegotiateIcon />;
    } else if (props.icon === "sign") {
      return <SignIcon />;
    }
  }
  return (
    <Stack
      style={{
        backgroundColor: props.color,
        alignItems: "flex-start",
        flex: props.flex,
        margin: "0% 0.8%",
        borderRadius: "0.4rem",
        cursor: "pointer",
        justifyContent: "flex-start",
        height: 80,
        alignSelf: "center",
      }}
      className="Button"
      onClick={props.onClickFunction}
    >
      <div style={{ marginTop: 8, marginBottom: 2 }}>
        <Text style={styles.headerText}>{props.buttonText.split("-")[0]}</Text>
      </div>
      <Stack
        style={{
          display: "flex",
          backgroundColor: "",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 4,
        }}
      >
        {getIcon()}
        <Text style={styles.headerStyle}>{props.buttonText.split("-")[1]}</Text>
      </Stack>
    </Stack>
  );
};

import { FC, useState, useEffect } from "react";
import { Document, Project, UserModel } from "../../../models";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { ProjectCard } from "../ProjectCard";
import {
  IStackTokens,
  IStackStyles,
  Text,
  Stack,
  IPlainCardProps,
  HoverCard,
  HoverCardType,
  IStackProps,
} from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { colorPallete } from "../../../assets/color";
import {
  getColor,
  getFormattedDate,
  getSortedArrayMRU,
  getSplitDocumentName,
} from "../../../utils";
import OpenFile from "../../../navigation/NoobOrPro";
import TrashIcon from "../../../assets/Icons/svg/trashIcon";
import useModal from "../../../hooks/useModal";
import DeleteProjectModal from "../../modals/DeleteProjectModal";
import { EditProjectModal } from "../../modals/EditProjectModal";
import { ProjectService } from "../../../services/ProjectService";

const innerStackTokens: IStackTokens = {
  childrenGap: "2%",
};

const outerStackStyles: IStackStyles = {
  root: {
    paddingLeft: "4%",
    paddingRight: "4%",
  },
};

type Props = {
  user?: UserModel;
  project: Project;
  onClick?: () => void;
  sender?: string;
};

const CounterPartiesHoverCard: React.FC<{ counterParties: string[] }> = ({
  counterParties,
}) => {
  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard: () => (
      <div style={{ padding: "5px", backgroundColor: "white" }}>
        <ul style={{ listStyleType: "none" }}>
          {counterParties.map((counterParty) => (
            <li key={counterParty}>{counterParty}</li>
          ))}
        </ul>
      </div>
    ),

    directionalHint: 1,
  };

  return (
    <HoverCard type={HoverCardType.plain} plainCardProps={plainCardProps}>
      <div
        style={{
          fontSize: "14px",
          borderRadius: "1rem",
          minWidth: "1px",
          maxWidth: "fit-content",
          color: "rgb(6 42 108)",
        }}
      >
        {counterParties.length === 1
          ? `+${counterParties.length} Other`
          : `+${counterParties.length} Others`}
      </div>
    </HoverCard>
  );
};

export const ProjectInCard: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isModalOpen, showModal, hideModal } = useModal();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [docData, setDocData] = useState<Document[]>([]);
  const [projectValue, setProjectValue] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (props?.project?.documents && props?.project?.documents.length > 0) {
      const sortedArray = getSortedArrayMRU(props?.project?.documents);
      setDocData(sortedArray);
    } else {
      setDocData([]);
    }
  }, [props]);
  useEffect(() => {
    const fetchStatus = async () => {
      try {
        if (props?.project) {
          const currentValue: any = await ProjectService.projectInfo(
            Number(projectId)
          );
          setProjectValue(currentValue.data.value);
        } else {
        }
      } catch (error) {
        console.error("Error fetching status:", error);
      }
    };
    fetchStatus();
  }, [props.project]);

  const showDocuments = (projectId: number | undefined) => {
    navigate(`${projectId}`, {
      state: {
        review: false,
        fileName: null,
      },
    });
  };

  function showEditModal() {
    setModalOpen(true);
  }

  function hideEditModal() {
    setModalOpen(false);
  }

  // Updated renderPerson function
  const renderCounterParties = (data: string | undefined) => {
    let counterParties = data?.split(";");
    if (counterParties && counterParties.length > 0) {
      let remainingCount = counterParties.length - 2;
      const horizontalStackProps: IStackProps = {
        style: { width: "100%", flexWrap: "wrap" },
      };

      return (
        <Stack {...horizontalStackProps}>
          {counterParties.slice(0, 2).map((name) => {
            return (
              <HorizontalStack style={styles.counterPartyContainer} key={name}>
                <Stack
                  style={{
                    ...styles.counterPartyLogo,
                    backgroundColor: getColor(name.trim()),
                  }}
                >
                  <Text style={styles.counterPartyInitial}>
                    {name.trim().charAt(0).toUpperCase()}
                  </Text>
                </Stack>
                <Text style={styles.counterParty}>{name.trim()}</Text>
              </HorizontalStack>
            );
          })}

          {remainingCount > 0 && (
            <CounterPartiesHoverCard counterParties={counterParties.slice(2)} />
          )}
        </Stack>
      );
    }
    return null;
  };

  const projectId = props.project?.projectId?.toString();

  const [editModalKey, setEditModalKey] = useState<number>(0);
  const resetEditModal = () => {
    hideEditModal();
    setEditModalKey((prevKey) => prevKey + 1);
  };
  const NumberWithCommas = (number: string) => {
    const addCommas = (num: string) => {
      const cleanedStr = num.replace(/\D/g, "");
      return "$" + cleanedStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    const formattedNumber = addCommas(number);
    return formattedNumber;
  };

  return (
    <ProjectCard>
      <div className="Card">
        <VerticalStack
          className="ProjectCardTop"
          onClick={() => {
            props.sender !== "AllProjects" &&
              showDocuments(props.project?.projectId);
          }}
        >
          <VerticalStack tokens={innerStackTokens} styles={outerStackStyles}>
            <Text className="ProjectName" title={props.project?.projectName}>
              {props.project?.projectName}
            </Text>
            <Text
              title={props.project?.counterParties}
              className="CounterParty"
            >
              {t("counter_parties")}
            </Text>
            {props.project?.counterParties &&
              renderCounterParties(props.project?.counterParties)}
          </VerticalStack>
        </VerticalStack>
        <VerticalStack
          style={{
            backgroundColor: "",
            position: "absolute",
            bottom: docData.length === 1 ? 72 : 40,
            width: "80%",
            marginLeft: "4%",
          }}
        >
          <Text
            key={docData[0]?.documentId}
            style={{
              ...styles.documentName,
              position: "relative",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
            title={docData[0]?.rootDocumentName}
          >
            {projectValue > 0
              ? `Value : ${NumberWithCommas(String(projectValue))}`
              : "Value"}
          </Text>
        </VerticalStack>

        <HorizontalStack
          style={{
            ...styles.date,
            position: "absolute",
            width: "87%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text style={{ ...styles.date, marginLeft: 0 }}>
            {getFormattedDate(String(props.project?.createdAt))}
          </Text>
          {window.location.pathname.includes(projectId!) ? (
            <HorizontalStack horizontalAlign="end" style={{ width: "25%" }}>
              <Stack
                style={{ cursor: "pointer" }}
                onClick={() => {
                  showModal();
                }}
              >
                <TrashIcon />
              </Stack>
            </HorizontalStack>
          ) : null}
        </HorizontalStack>
      </div>
      <DeleteProjectModal
        isModalOpen={isModalOpen}
        showModal={showModal}
        hideModal={hideModal}
        projectName={props.project?.projectName}
        projectId={props.project?.projectId}
      />
      <div key={editModalKey}>
        <EditProjectModal
          isModalOpen={modalOpen}
          showModal={showEditModal}
          hideModal={resetEditModal}
          projectName={props.project?.projectName}
          projectOwner={props.project?.projectOwner}
          counterParties={props.project?.counterParties}
          createdDate={props.project?.createdAt}
          description={props.project?.description}
        />
      </div>
    </ProjectCard>
  );
};

const styles = {
  counterPartyLogo: {
    height: "32px",
    width: "32px",
    // backgroundColor: colorPallete.btBlue,
    borderRadius: "16px",
    alignItems: "center",
    justifyContent: "center",
  },
  counterPartyInitial: {
    fontSize: "13px",
    lineHeight: "16px",
    color: "#F4F4F4",
  },
  counterParty: {
    paddingLeft: "4%",
    fontSize: "13px",
    lineHeight: "16px",
    fontWeight: "400",
    color: colorPallete.black1,
    backgroundColor: "",
    fontFamily: "Poppins",
  },
  counterPartyContainer: {
    alignItems: "center",
    justifyContent: "flex-start",
    paddingBottom: "3.5%",
    backgroundColor: "",
    paddingTop: "0.5%",
  },
  bodyStyles: { paddingTop: "2%", paddingBottom: "4%", backgroundColor: "" },
  documentName: {
    fontSize: "14px",
    color: colorPallete.grey4,
    backgroundColor: "",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontWeight: 400,
    fontFamily: "Poppins",
  },
  blueText: {
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "var(--primary-blue, #18468F)",
    fontSize: "12px",
    fontStyle: "normal",
  },
  date: {
    display: "flex",
    marginLeft: "4%",
    fontSize: "12px",
    color: "#717070",
    bottom: 12,
    fontFamily: "Poppins",
  },
};

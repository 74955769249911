import {
  HoverCard,
  HoverCardType,
  IPlainCardProps,
  Stack,
  Text,
} from "@fluentui/react";
import React, { FC, useState } from "react";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { DocumentWithTrack } from "../NewShareModal";
import { useTranslation } from "react-i18next";
import { Document } from "../../../models";
import Draft from "./icons/Draft";
import Recieved from "./icons/Received";
import Sent from "./icons/Sent";
import Cross from "./icons/Cross";
import CustomToggle from "../../CustomToggle";
import InfoIcon from "./icons/infoIcon";
import CleanInfoIcon from "./icons/cleanInfoIcon";
import Redline from "./icons/Redline";
import { getSplitDocumentName } from "../../../utils";

type SelectedDocumentProps = {
  modal?: string;
  setSelectedDocuments?: React.Dispatch<React.SetStateAction<Document[]>>;
  documentWithTrack: DocumentWithTrack;
  setDocumentWithTrack: React.Dispatch<
    React.SetStateAction<DocumentWithTrack[]>
  >;
  length: number;
};

const SelectedDocumentStyles = {
  width: "208px",
  height: "40px",
  padding: "6px 10px",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "6px",
  border: "1px solid var(--LIGHT-BLUE, #E8ECF4)",
  fontFamily: "Poppins",
  fontSize: "9px",
  fontWeight: 500,
};

const hoverCardStyles = {
  width: "259px",
  padding: "12px",
  alignItems: "flexStart",
  flexShrink: 0,
  alignSelf: "stretch",
  borderRadius: "8px",
  backgroundColor: "#fff",
  boxShadow:
    "0px 4px 6px -2px rgba(16, 24, 40, 0.06), 0px 12px 16px -4px rgba(16, 24, 40, 0.12)",
  zIndex: 3,
};

const versionDocumentStyle = {
  padding: "4px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "4px",
  border: "1px solid var(--LIGHT-BLUE, #E8ECF4)",
  backgroundColor: "#FFF",
  fontFamily: "Poppins",
  fontSize: "10px",
  fontWeight: 500,
  color: "#18468F",
  height: "20px",
};

const DocumentTrackCard: FC<SelectedDocumentProps> = (props) => {
  const [active, setActive] = useState<boolean>(
    props.documentWithTrack.isTracked
  );
  const { t } = useTranslation();

  function handleToggleChange() {
    setActive(!active);
    props?.setDocumentWithTrack((prev) =>
      prev.map((doc) =>
        doc.document.rootDocumentId ===
        props.documentWithTrack.document.rootDocumentId
          ? { ...doc, isTracked: !active }
          : doc
      )
    );
  }
  const handleRemoveDocument = () => {
    props?.setDocumentWithTrack((pre) =>
      pre.filter(
        (doc) =>
          doc.document.rootDocumentId !==
          props.documentWithTrack.document.rootDocumentId
      )
    );
    // props?.setSelectedDocuments && props?.setSelectedDocuments((pre)=>
    //   pre.filter(
    //     (doc)=>
    //       doc.documentId !== props.documentWithTrack.document.documentId
    //   )
    // )
  };

  const TextHoverCard = () => {
    const textCardProps: IPlainCardProps = {
      onRenderPlainCard: () => (
        <Stack
          style={{
            ...hoverCardStyles,
            minHeight: active ? "168px" : "108px",
          }}
        >
          <Text
            className="hoverText"
            style={{
              color: active ? "#18468F" : "#A82C0C",
            }}
          >
            {active ? t("clean_document?") : t("share_changes?")}
          </Text>
          <Text className="bodyText">
            {active ? t("clean_doc_body") : t("share_track_body")}
          </Text>
        </Stack>
      ),
      directionalHint: 11,
    };
    return (
      <HoverCard
        type={HoverCardType.plain}
        plainCardProps={textCardProps}
        style={{ alignItems: "center", borderRadius: "8px" }}
        className="hoverCard"
      >
        <HorizontalStack style={{ alignItems: "center", gap: "2px" }}>
          <Text
            style={{
              fontFamily: "Poppins",
              fontSize: "9px",
              fontWeight: 500,
              color: active ? "#18468F" : "#A82C0C",
              position: "relative",
              cursor: "pointer",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: active ? "77px" : "97px",
              lineHeight: "12px",
            }}
          >
            {active ? t("clean_document") : t("share_changes")}
          </Text>
          {active ? (
            <Stack
              style={{
                cursor: "pointer",
              }}
            >
              <CleanInfoIcon />
            </Stack>
          ) : (
            <Stack
              style={{
                cursor: "pointer",
              }}
            >
              <InfoIcon />
            </Stack>
          )}
        </HorizontalStack>
      </HoverCard>
    );
  };

  function getIcon(fileType: string, isRedlined: any) {
    if (fileType === "draft") {
      if (isRedlined === true) {
        return <Redline />;
      } else {
        return <Draft />;
      }
    } else if (fileType === "received") {
      return <Recieved />;
    } else {
      return <Sent />;
    }
  }

  if (props.modal === "share") {
    return (
      <div style={{ position: "relative" }}>
        {props.length > 1 ? (
          <div
            style={{
              position: "absolute",
              top: "-7px",
              right: "0",
              transform: "translate(50%, 0)",
              cursor: "pointer",
            }}
            onClick={handleRemoveDocument}
          >
            <Cross color={active ? "#18468F" : "#A82C0C"} />
          </div>
        ) : null}
        <HorizontalStack
          style={{
            ...SelectedDocumentStyles,
            backgroundColor: active ? "#E8ECF4" : "#FCF0ED",
            border: active ? "1px solid #E8ECF4" : "1px solid #FCF0ED",
          }}
        >
          <HorizontalStack style={{ gap: "8px", alignItems: "center" }}>
            {props?.documentWithTrack.document?.fileType &&
              getIcon(
                props?.documentWithTrack?.document?.fileType,
                props?.documentWithTrack?.document?.isRedlined
              )}
            <VerticalStack style={{ gap: "4px" }}>
              <Text
                style={{
                  fontFamily: "Poppins",
                  fontSize: "9px",
                  fontWeight: 600,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "130px",
                  lineHeight: "12px",
                }}
                title={props?.documentWithTrack.document?.rootDocumentName}
              >
                {props?.documentWithTrack.document?.rootDocumentName?.replace(
                  /\.[^/.]+$/,
                  ""
                )}
              </Text>
              <HorizontalStack style={{ alignItems: "center", gap: "4px" }}>
                <CustomToggle
                  active={active}
                  setActive={setActive}
                  height="12px"
                  width="22px"
                  borderRadius="12px"
                  handleChange={handleToggleChange}
                  redText={t("active") ?? ""}
                  blueText={t("clean") ?? ""}
                />
                <TextHoverCard />
              </HorizontalStack>
            </VerticalStack>
          </HorizontalStack>
          <HorizontalStack
            style={{
              ...versionDocumentStyle,
              color: active ? "#18468F" : "#A82C0C",
              border: active ? "1px solid #E8ECF4" : "1px solid #F9E0DA",
            }}
          >
            {`V${props?.documentWithTrack.document?.documentVersion}`}
          </HorizontalStack>
        </HorizontalStack>
      </div>
    );
  } else {
    return (
      <HorizontalStack className="shareTrackVersionsCard">
        {props?.documentWithTrack?.document?.fileType &&
          getIcon(
            props?.documentWithTrack?.document?.fileType,
            props?.documentWithTrack?.document?.isRedlined
          )}
        <Text
          className="shareTrackRedText"
          style={{
            color: active ? "#18468F" : "#A82C0C",
            border: active ? "1px solid #E8ECF4" : "1px solid #F9E0DA",
          }}
        >
          V{props?.documentWithTrack?.document?.documentVersion}
        </Text>
        <Text
          className="shareTrackMedText"
          title={
            props?.documentWithTrack?.document?.rootDocumentName.split(
              ".docx"
            )[0]
          }
        >
          {getSplitDocumentName(
            props?.documentWithTrack?.document?.rootDocumentName
          )}
        </Text>
        <Stack style={{ position: "absolute", right: 16 }}>
          <CustomToggle
            active={props?.documentWithTrack?.isTracked}
            setActive={setActive}
            height="21px"
            width="62px"
            borderRadius="12px"
            handleChange={handleToggleChange}
            data={true}
            redText={t("active") ?? ""}
            blueText={t("clean") ?? ""}
          />
        </Stack>
      </HorizontalStack>
    );
  }
};

export default DocumentTrackCard;

import {
  DefaultButton,
  IContextualMenuProps,
  Stack,
  Text,
  mergeStyles,
} from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { TooltipHost } from "@fluentui/react";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { BackButton } from "../../BackButton";
import { ProjectInCard } from "../ProjectInCard";
import { UserModel } from "../../../models";
import { useNavigate, useParams } from "react-router-dom";
import { colorPallete } from "../../../assets/color";
import "./index.css";
import { useTranslation } from "react-i18next";
import { ProjectCard } from "../ProjectCard";
import { getColor, getFullName } from "../../../utils";
import { useAppSelector } from "../../../hooks/hooks";
import { CloseProjectModal } from "../../modals/CloseProjectModal";
import useModal from "../../../hooks/useModal";
import { ProjectService } from "../../../services/ProjectService";
import CustomToggle from "../../CustomToggle";
import Loader from "../../Loader";

const styles = {
  counterPartyLogo: {
    height: "1.6rem",
    minWidth: "1.6rem",
    backgroundColor: colorPallete.btBlue,
    borderRadius: "16px",
    alignItems: "center",
    justifyContent: "center",
  },
  membersLogo: {
    height: "16px",
    minWidth: "16px",
    borderRadius: "16px",
    alignItems: "center",
    justifyContent: "center",
  },
  Initial: {
    fontSize: "10px",
    lineHeight: "16px",
    color: "#F4F4F4",
    fontFamily: "Poppins",
  },
  text: {
    paddingLeft: "5%",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "400",
    color: colorPallete.black1,
    paddingRight: "2%",
    width: "max-content",
    fontFamily: "Poppins",
  },

  counterParty: {
    paddingLeft: "5%",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "400",
    color: colorPallete.black1,
    paddingRight: "2%",
    width: "max-content",
    fontFamily: "Poppins",
  },
  counterPartyObject: {
    marginLeft: "1%",
    marginRight: "0.25%",
    marginTop: "0.5%",
    border: "1px solid #D0D5DD",
    borderRadius: "4px",
    padding: "4px 10px 4px 4px",
    overflow: "hidden",
    height: "40px",
    backgroundColor: "",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  counterPartiesContainer: {
    display: "flex",
    width: "100%",
    overflow: "auto",
    height: "100%",
    backgroundColor: "",
  },
  descriptionStack: {
    width: "100%",
    alignSelf: "flex-end",
    marginBottom: "1%",
    padding: "1% 0%",
  },
  horizontalLine: {
    width: "100%",
    height: "2px",
    backgroundColor: colorPallete.grey2,
    alignSelf: "center",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    paddingTop: "10%",
  },
  bodyStyles: {
    fontSize: "15px",
    color: colorPallete.grey1,
    alignSelf: "flex-end",
    fontFamily: "Poppins",
  },
  projectOwnerStack: {
    width: "100%",
    alignSelf: "flex-end",
    marginTop: "1%",
    marginBottom: "1%",
  },
  textHead: {
    marginTop: "1%",
    fontWeight: 500,
    fontSize: "15px",
    minWidth: "8.5rem",
    width: "fit-content",
    color: colorPallete.grey1,
    fontFamily: "Poppins",
    lineHeightL: "20px",
  },
  textTail: {
    fontWeight: 500,
    fontSize: "15px",
    marginTop: "1%",
    fontFamily: "Poppins",
  },
  projectOwnerText: {
    marginRight: "1%",
    marginTop: "0.5%",
    padding: "0.5%",
    borderRadius: "1rem",
    fontFamily: "Poppins",
  },
  memberText: {
    width: "100%",
    alignSelf: "flex-end",
    justifyContent: "center",
    marginTop: "0.25%",
    fontFamily: "Poppins",
  },

  membersContainer: {
    display: "flex",
    width: "100%",
    overflow: "auto",
    height: "100%",
    marginLeft: "0.8%",
  },
  membersOfProject: {
    border: "1px solid #D0D5DD",
    borderRadius: "4px",
    padding: "4px 4px 4px 5px",
    overflow: "hidden",
    height: "45px",
    backgroundColor: "",
    marginRight: "10px",
    marginBottom: "10px",
  },
};

export const ProjectDetailsCard: FC<any> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [workingGroups, setWorkingGroups] = useState<UserModel[]>([]);
  const user = JSON.parse(localStorage.getItem("user")!);
  const getWorkingGroup = useAppSelector(
    (state: { getWorkingGroup: any }) => state.getWorkingGroup
  );
  const [projectCloseAction, setProjectCloseAction] = useState("Active");
  const [isOwner, setIsOwner] = useState(false);
  const [status, setStatus] = useState("");

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (getWorkingGroup.data && getWorkingGroup.data.length > 0) {
      setWorkingGroups([...getWorkingGroup.data, user]);
    } else {
      setWorkingGroups([user]);
    }
  }, [getWorkingGroup.data]);
  let { projectId } = useParams();

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        if (props.projectData) {
          const currentStatus: any = await ProjectService.projectInfo(
            Number(projectId)
          );
          props.setActive(
            currentStatus.data.status === "Active" ? true : false
          );
          setStatus(currentStatus.data.status);
        } else {
        }
      } catch (error) {
        console.error("Error fetching status:", error);
      }
    };

    fetchStatus();
  }, [props.projectData]);

  function click() {
    navigate("/home");
  }

  const membersJsx = (user: UserModel) => {
    const domain = user?.email?.split("@")[1];
    const organization = domain?.split(".com")[0];
    const orgName =
      organization?.charAt(0)?.toUpperCase() + organization?.slice(1);
    return (
      <Stack
        style={styles.membersOfProject}
        title={getFullName(user)}
        key={user?.email}
      >
        <VerticalStack
          style={{ justifyContent: "space-evenly", width: "fit-content" }}
        >
          <HorizontalStack
            style={{ alignItems: "center", backgroundColor: "" }}
          >
            <Stack
              style={{
                ...styles.membersLogo,
                backgroundColor: getColor(getFullName(user)),
              }}
            >
              <Text style={styles.Initial}>
                {getFullName(user)?.charAt(0)?.toUpperCase()}
              </Text>
            </Stack>
            <Text
              style={{
                ...styles.text,
                whiteSpace: "nowrap",
                fontFamily: "Poppins",
                paddingRight: "28px",
              }}
            >
              {getFullName(user)}
            </Text>
          </HorizontalStack>
          <Text
            style={{
              fontSize: "12px",
              color: "#707070",
              lineHeight: "16px",
              padding: "0px 23px",
              fontFamily: "Poppins",
            }}
          >
            {orgName}
          </Text>
        </VerticalStack>
      </Stack>
    );
  };

  const projectNameStyles = mergeStyles({
    fontSize: "24px",
    fontWeight: 500,
    fontFamily: "Poppins",
  });

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: "closeButton",
        text: "Close",
        onClick: closeProject,
      },
      {
        key: "abandonButton",
        text: "Abandon",
        onClick: abandonProject,
      },
    ],
  };
  function activateProject() {
    setProjectCloseAction("Active");
    showModal();
  }
  function closeProject() {
    setProjectCloseAction("Close");
    showModal();
  }
  function abandonProject() {
    setProjectCloseAction("Abandon");
    showModal();
  }

  function handleToggleChange() {
    props.setActive(!props.active);
    const payload = {
      projectId: props?.projectData?.projectId,
      status: props.active ? "Closed" : "Active",
      value: 0,
      dispositionReason: "",
    };
    props.setIsLoading(true);
    ProjectService.changeStatusOfProject(payload)
      .then((res) => {
        if (res.code === 200) {
          setIsSuccess(true);
          props.showToggleProjectModal();
        } else setIsError(true);
        props.setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
      });

    hideModal();
  }
  useEffect(() => {
    setIsOwner(
      props.projectData.ownerEmail ===
        JSON.parse(localStorage.getItem("user")!)?.email
    );
  }, [props]);
  const { isModalOpen, showModal, hideModal } = useModal();

  return (
    <Stack className="ProjectContainer">
      <VerticalStack className="ProjectCardStack">
        {props.projectData && (
          <ProjectInCard project={props?.projectData} sender="AllProjects" />
        )}
      </VerticalStack>
      <div className="ProjectDetails">
        <VerticalStack
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <HorizontalStack
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            <BackButton onClickFunction={click} />
            <TooltipHost
              content={
                "Only project owner is authorized to change project status"
              }
            >
              <CustomToggle
                active={props.active}
                setActive={props.setActive}
                height="20px"
                width="64px"
                switchDotHeight="18px"
                switchDotWidth="18px"
                borderRadius="12px"
                customInactiveLeft="3%"
                customActiveLeft="calc(100% - 28.7%)"
                handleChange={handleToggleChange}
                redText={"Closed"}
                blueText={"Active"}
                data
                disabled={!isOwner}
              />
            </TooltipHost>
          </HorizontalStack>
          <HorizontalStack style={{ width: "100%" }}>
            <Text className={projectNameStyles}>
              {props.projectData?.projectName}
            </Text>
          </HorizontalStack>
          <HorizontalStack style={styles.descriptionStack}>
            <Text style={styles.bodyStyles}>
              {props.projectData?.description}
            </Text>
          </HorizontalStack>
        </VerticalStack>

        <Stack style={styles.horizontalLine}></Stack>
        <HorizontalStack
          style={styles.projectOwnerStack}
          title={props.projectData?.ownerName}
        >
          <Text style={styles.textHead}>{t("project_owners")}:</Text>
          <Stack style={styles.projectOwnerText}>
            <HorizontalStack>
              <Text
                style={{
                  ...styles.text,
                  whiteSpace: "nowrap",
                  paddingTop: "4%",
                  fontWeight: 600,
                }}
              >
                {props.projectData?.ownerName}
              </Text>
            </HorizontalStack>
          </Stack>
        </HorizontalStack>
        <HorizontalStack style={styles.memberText}>
          <Text style={styles.textHead}>{t("working_group")}:</Text>
          {/* <Text style={styles.textTail}>:</Text> */}
          <Stack
            style={{
              ...styles.membersContainer,
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {workingGroups &&
              workingGroups.length > 0 &&
              workingGroups.map((user: UserModel) => {
                return membersJsx(user);
              })}
          </Stack>
        </HorizontalStack>
      </div>
    </Stack>
  );
};
